






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import adsCardStatuses from "@/constants/adsCardStatuses";
import makeSelectOptions from "@common/util/makeSelectOptions";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
        },
        content: {
          name: "Quản lý thẻ",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(adsCardStatuses),
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
            },
            description: {
              text: "Mô tả",
              sortable: true,
            },
            status: {
              text: "Trạng thái",
              options: {
                label(value) {
                  return adsCardStatuses[value];
                },
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            insert: {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt", "ksnb", "ksnbl"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("adsCards", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("adsCards", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "adsCards",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("adsCards", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(adsCardStatuses),
                },
              },
            },
            insertForm: userManager.checkRole(["admin", "kt", "ksnb", "ksnbl"]) && {
              content: {
                fieldNames: ["name", "description", "status"],
              },
            },
            editForm: userManager.checkRole(["admin", "kt", "ksnb", "ksnbl"]) && {
              content: {
                fieldNames: ["name", "description", "status"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
